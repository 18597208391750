import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login'
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      requireAuth: false
    }
  },
  {
    path: "*",
    redirect: 'error-404'
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  }
]

const router = new VueRouter({ mode: 'history', base: process.env.BASE_URL, routes })

// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     if (router.app.$store.state.api.login_status && router.app.$store.state.api.login_data!='' && router.app.$store.state.api.login_data!=null) {
//       next() // 往下執行
//     } else {
//       //如果使用者token無效則跳轉到login頁面
//       if (to.path !== '/login') {
//         next('/login')
//       } else {
//         next() // 往下執行
//       }
//     }
//   } else if (!to.meta.requireAuth && router.app.$store.state.api.login_status && router.app.$store.state.api.login_data!='' && router.app.$store.state.api.login_data!=null) {
//     next('/members')
//   } else {
//     next() // 往下執行
//   }
// })

export default router
