// for data that needs to store consistently

import axios from 'axios'

//const axiosURI = 'https://heureuxy-api.innpressionhost.com/backend/request.php'

const state = {
  login_data: null,
  login_status: false,
}

const getters = {
  login_data: state => state.login_data,
  login_status: state => state.login_status,
}

const actions = {
  async getData({
    commit
  }, data) {
    const response = await axios.post(axiosURI, data.params)
    commit(data.method, response.data)
  },
  reset({
    commit
  }, data) {
    commit(data.method)

  }
}

const mutations = {
  getLoginData: function (state, result) {
    let resultData = JSON.parse(JSON.stringify(result));
    if (resultData.result == "success") {
      state.login_data = resultData.data.user;
      state.login_status = true
    } else {
      state.login_data = "";
      state.login_status = false
    }
  },
  resetData: function (state) {
    // state.login_data = [];
    // state.login_status = false
    Object.assign(state, {
      login_data: null,
      login_status: false,
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
