<template>
  <!-- https://ithelp.ithome.com.tw/articles/10223518 -->
  <!-- $emit used when child element want to pass some data to parent element-->
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-slide-x-transition>
          <h2 class="app-title text--primary">MATERIO</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5" v-for="link in nav_link" :key="link.title_zh">
      <nav-menu-link v-if="!link.isGroup" :title="link.title_zh" :to="link.name" :id="link.id"></nav-menu-link>
      <nav-menu-group v-else :title="link.title_zh">
        <nav-menu-link
          v-for="sub_group in link.subGroup"
          :key="sub_group.title_zh"
          :title="sub_group.title_zh"
          :to="sub_group.name"
          :id="link.id"
        ></nav-menu-link>
      </nav-menu-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    nav_link: [
      {
        isGroup: false,
        title_zh: '會員',
        name: { name: 'Members' },
        id: 'members',
      },
      {
        isGroup: false,
        title_zh: '商品',
        name: { name: 'Products' },
        id: 'products',
      },
      {
        isGroup: false,
        title_zh: '訂單',
        name: { name: 'Order' },
        id: 'order',
      },
      {
        isGroup: false,
        title_zh: '超新星禮包換領',
        name: { name: 'Nova' },
        id: 'nova',
      },
      {
        isGroup: false,
        title_zh: '最新消息',
        name: { name: 'Blog' },
        id: 'blogs',
      },
      {
        isGroup: false,
        title_zh: '查詢',
        name: { name: 'ContactUs' },
        id: 'contact_us',
      },
      {
        isGroup: false,
        title_zh: 'HEU轉換記錄',
        name: { name: 'HEUTransferRecord' },
        id: 'heu_transfer_record',
      },
      {
        isGroup: false,
        title_zh: 'HEU領取記錄',
        name: { name: 'HEUReducedRecord' },
        id: 'heu_reduced_record',
      },
      {
        isGroup: false,
        title_zh: '設定',
        name: { name: 'Setting' },
        subGroup: [
          { title_zh: '首頁', name: { name: 'error-404' } },
          { title_zh: 'VIP推薦激賞設定', name: { name: 'error-404' } },
          { title_zh: 'VVIP卓越激賞', name: { name: 'error-404' } },
          { title_zh: '複購激賞設定', name: { name: 'error-404' } },
          { title_zh: '股權積分設定  (New data)', name: { name: 'error-404' } },
          { title_zh: '提現手續費設定', name: { name: 'error-404' } },
          { title_zh: '運費', name: { name: 'error-404' } },
        ],
        id: 'setting',
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
